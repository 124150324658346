import './App.scss';
import { useState } from "react"
import { Button } from "react-bootstrap"

export default function AdminPage() {

    const [serverData, setServerData ] = useState(null)
    const [ frontpageServerData, setFrontpageServerData ] = useState(null)
    function formSubmit(event) {
        console.log("formSubmit")
        //var url = "https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/admin";
        var url = "https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/admin";
        var request = new XMLHttpRequest();
        request.open('POST', url, true);
        request.onload = function() {
            
            setServerData(request.responseText)
            alert(serverData)
        };
        
        request.onerror = function() {
            setServerData(request.responseText)
            alert(serverData)
        };
        let formData = new FormData()
        let action = {}
        action.content = document.getElementById("content").value;
        action.pageLink = document.getElementById("pageLink").value;
        action.operation = "uploadQuestions";
        action.examName = document.getElementById("name").value;

        formData.append("token", document.getElementById("avain").value)
        formData.append("act", JSON.stringify(action))
        event.preventDefault()
        request.send(formData);
    }
    /*
    function displayReturn() {
        console.log(serverData)
    }*/

    function uploadMEX() {
        var request = new XMLHttpRequest();
        request.open("POST", "https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/admin", true)
        request.onload = function() {
            
            setServerData(request.responseText)
            alert(request.responseText)
        };
        
        request.onerror = function() {
            setServerData(request.responseText)
            alert(request.responseText)
        };
        let formData = new FormData()
        let action = {
            operation: "uploadQuestionsMEX",
            pageLink: document.getElementById("pageLink").value,
            content: document.getElementById("content").value
        }
        formData.append("token", document.getElementById("avain").value)
        formData.append("act", JSON.stringify(action))
        request.send(formData)
        
    }

    function removeOrphans() {
        var request = new XMLHttpRequest();
        request.open("POST", "https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/admin", true)
        request.onload = function() {
            
            setServerData(request.responseText)
            alert(request.responseText)
        };
        
        request.onerror = function() {
            setServerData(request.responseText)
            alert(request.responseText)
        };
        let formData = new FormData()
        let action = {
            operation: "assignOrphans",
        }
        formData.append("token", document.getElementById("avain").value)
        formData.append("act", JSON.stringify(action))
        request.send(formData)
        
    }

    function ExamList() {

        if (frontpageServerData == null) {
            var request = new XMLHttpRequest();
            request.open("GET", "https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/frontpage2?empties=true", true)
            request.onload = function() {
                console.log("Got frontpage: " + request.responseText)
                setFrontpageServerData(JSON.parse(request.responseText))
                console.log(request.responseText)
            };

            request.onerror = function() {
                setFrontpageServerData(request.responseText)
                alert("Virhe haettaessa palvelintietoja")
            };
            request.send()
            return <p>Haetaan koelistaa palvelimelta...</p>
        }
        
        let subjectlist = {}
        for (let exam of frontpageServerData.exams) {
            let subject = exam.identifier.split("_")[0]
            let season = exam.identifier.split("_")[1]
            let year = exam.identifier.split("_")[2]
            if (subjectlist[subject] === undefined) subjectlist[subject] = []
            subjectlist[subject].push(season + " " + year)
        }
        return (<div style={{margin: "2%"}}>
            <h5>Ladattujen kokeiden lista: </h5>
            {Object.entries(frontpageServerData.subjects).map(([subject, exams]) => {
                return (<div>
                    <b>{subject} : </b>
                    {Object.values(exams).map(entry => <p>{entry[0].replaceAll("_"," ")} ({entry[1]})</p>)}
                </div>)
            })}
        </div>)
    }

    //"https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/
    return (
        <>
            <h5>Hallintopaneeli</h5>
            <form action="https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/admin" encType="multipart/form-data" method="post" onSubmit={formSubmit}>
                <br></br>
                <label for="name">Kokeen nimi (esim. historia_kevät_2023)</label>
                <input id="name" name="name" type="text"/>
                <br></br>
                <label for="pageLink">Linkki koesivulle (aineistoja varten)</label>
                <input id="pageLink" name="pageLink" type="text"/>
                <br></br>
                <label for="avain">Huoltoavain</label>
                <input id="avain" name="avain" type="text"/>
                <br></br>
                <label for="content">Abittikokeen HTML-lähdekoodi</label>
                <br></br>
                <textarea id="content" name="content" type="textarea" style={{width: "80%", height: "80vh"}}></textarea>
                <input type="submit" value="Perinteinen vastauksenlisäys (vaatii HTML-lähdekoodin ja kaikki kentät)"></input>
                
                <hr></hr>
                <Button onClick={uploadMEX}>MEX-lataus (yrittää automaattisesti etsiä mex-lähdekoodin. Vaatii abitreeni-linkin sekä 'main-bundle.js' -sivun lähdekoodin. Käytä vain, jos tiedät mitä teet.)</Button>
                <hr></hr>
                
                <Button onClick={removeOrphans}>Orpovastausten poisto (paina tätä kun olet varma, että uudet kokeet on onnistuneesti ladattu)</Button>
                <br></br>
                <hr></hr>
                <br></br>
                <ExamList></ExamList>
            </form>
            <br></br>
        </>
    )
}