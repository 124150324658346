import Modal from "react-bootstrap/Modal"
import { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

function AnswerView(props) {
    let [answerCache, setAnswerCache] = useState(undefined)

    function pretty(text) {
        return text.replaceAll("\n", "\n\n").trim()
    }

    function renderAnswerContent(answer) {

        if (answer === undefined) {
            return <p style={{minHeight: "200vh"}}></p>
        }

        if (answer !== undefined && answer.success === false) {
            return <Alert variant="danger">{answer.details}</Alert>
        }

        if (answer.question.subquestions !== undefined) {
            if (Object.keys(answer.texts).length !== Object.keys(answer.question.subquestions).length) {
                return (
                    <Alert variant="danger">Vastauksessa on eri määrä alavastauksia kuin kysymyksessä alakysymyksiä!</Alert>
                )
            }

            return (
            <>

                <h5>{answer.question.index + ". " + pretty(answer.question.header.replaceAll("\n",""))}</h5>
                {answer.question.description !== undefined && answer.question.description !== "" && (
                    <p>{answer.question.description.trim()}
                    </p>
                )}
                
                {Array.from({ length: Object.keys(answer.question.subquestions).length }).map((_, idx) => (
                    <div style={{whiteSpace: "pre-line"}}>
                        <br></br>
                        <hr></hr>
                        <Alert variant="light">
                            <h5>
                                Kysymys {answer.question.index + "." + Object.keys(answer.question.subquestions)[idx] + " (" + answer.question.subquestions[Object.keys(answer.question.subquestions)[idx]].points + "p)"}
                            </h5>
                            <p>
                                {answer.question.subquestions[Object.keys(answer.question.subquestions)[idx]].description.trim()}
                            </p>
                        </Alert>
                        <hr></hr>
                        <br></br>
                        {pretty(answer.texts[Object.keys(answer.question.subquestions)[idx]].text)}
                        <div className="Grayed">{"Vastauksen pituus: " + answer.texts[Object.keys(answer.question.subquestions)[idx]].text.replace(/\s/g, "").length + " merkkiä."}</div>
                    </div>
                ))}   
            </>
            )
        } else {
            return (<div style={{whiteSpace: "pre-line"}}>
                <Alert variant="light">
                    <h5>{answerCache.question.index + ". " + pretty(answerCache.question.header.replaceAll("\n",""))}</h5>
                    <p>{answerCache.question.description.trim()}</p>
                </Alert>
                <hr></hr>
                <br></br>
                {pretty(answer.text)}
                <div className="Grayed">{"Vastauksen pituus: " + answer.text.replace(/\s/g, "").length + " merkkiä."}</div>
            </div>)
        }
    }

    function getModalContent() {
        
        if (answerCache === undefined || props.id !== answerCache.id) {
            console.log("- GET MODAL CONTENT")
            // fetch("https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/answers/"+props.id)
            fetch("https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/answers/"+props.id)
                .then(response => {
                    if (!response.ok) throw new Error("Network error")
                    return response.json()
                })
                .then(data => {
                    setAnswerCache(data)
                    console.log("ANSWER CACHE IS NOW: ")
                    console.log(data)
                }) 
                .catch((error) => {
                    setAnswerCache({
                        success: false,
                        details: "Palvelimessa tapahtui virhe. Yritä myöhemmin uudelleen tai ota yhteys ylläpitoon."
                    })
                })
        }
    }
    
    //if (show != props.show) setShow(props.show)

    return (
        <>
            <Modal size = "lg" centered show={props.show} onHide = {() => {
                props.setAnswer({"show": false})
                window.history.replaceState(null, "", "/")
                console.log("Removing")
                setAnswerCache(undefined)
            }} onShow = {getModalContent}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div id="nameObject">
                            Vastaus {props.id}
                            <span className="MobileResponsive">
                                <Button variant="outline-primary" id="linkButton" onClick={ 
                                    () => {
                                        navigator.clipboard.writeText("https://mallivastaukset.fi/?answer=" + props.id)
                                        alert("Linkki kopioitu leikepöydällesi.")
                                    }

                                }>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
                                    <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
                                </svg>
                                </Button>
                                {" "}
                                {answerCache !== undefined && answerCache.question.sources && (
                                    
                                        <Button id="sourcesButton" style={{background: "#227bc9"}} href={answerCache.question.link} target="_blank">
                                            Avaa aineistot
                                        </Button>
                                        
                                )}
                                {" "}
                                {answerCache !== undefined && !props.favourite && props.updateFavourite !== null && (
                                <Button id="saveButton" variant="outline-warning" onClick={ () => props.updateFavourite()}>
                                    ☆ Lisää pikavalikkoon
                                </Button>
                                )}
                                {answerCache !== undefined && props.favourite && (
                                <Button id="saveButton" variant="warning" onClick={() => props.updateFavourite()}>
                                    ★ Lisätty pikavalikkoon
                                </Button>
                                )}
                            </span>
                        </div>
                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {renderAnswerContent(answerCache)}
                </Modal.Body>
            </Modal>
        </>
        
    )
}

export default AnswerView