import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import './index.css';
import App from './App';
import UploadPage from "./UploadPage"
import AdminPage from "./AdminPage"
import StatisticsPage from "./StatisticsPage"
import { Navbar, Nav } from 'react-bootstrap';

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log("Mallivastaukset B 2025/3")
root.render(
  <React.StrictMode>
    <BrowserRouter>


      <Navbar sticky="top" style={{  border:"0.7em", background: "#f9f9f9"}}>
        <Nav variant="pills" style={{marginLeft: "1em"}}>
          <Nav.Link className="Navbutton" href="/" active={!["submit","tilastot"].includes(document.URL.split("/").at(-1))}>
            <img
                alt=""
                src="logo512.png"
                width="30"
                height="30"
                style={{marginTop: "-0.15em"}}
              />{' '}
            <span style={{fontWeight: "bolder"}}>Mallivastaukset.fi</span>
          </Nav.Link>
          <div className="VerticalDivider"></div>
          <Nav.Link className="Navbutton HideOnMobile" href="/submit"   active={document.URL.split("/").at(-1) === "submit"}>Jaa omat vastauksesi</Nav.Link>
          <div className="VerticalDivider HideOnMobile"></div>
          <Nav.Link className="Navbutton" href="/tilastot" active={document.URL.split("/").at(-1) === "tilastot"}>Valmistujat</Nav.Link>
        </Nav>
      </Navbar>


      <Routes>
        <Route path = "*" index element={<App/>}></Route>
        <Route path="/submit" element={<UploadPage/>}></Route>
        <Route path="/tilastot" element={<StatisticsPage/>}></Route>
        <Route path="/admin" element={<AdminPage/>}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
  
);
