
import React, { useState } from "react"
import { Accordion, Button, ButtonGroup, FormCheck, Dropdown, ListGroup } from "react-bootstrap"
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const sessions = [
    "2024S",
    "2024K",
    "2023S",
    "2023K",
    "2022S",
    "2022K"
]
let gatherSession = null
function grout(student) {
    let out = ""
    let studentgrades = Object.values(student.grades)
    studentgrades.sort()   
    studentgrades.reverse()
    studentgrades.forEach((grade) => out += grades[grade] + " ")
    return out.substring(0, out.length-1)
}

function rnd(n) {
    return Math.floor(n * 100) / 100
}

function total(student) {
    let total = 0
    Object.values(student.grades).forEach(grade => {
        total += grade
    })
    return total
}

function letteravg(avg) {
    let rem = avg - Math.floor(avg)
    avg = Math.floor(avg)
    let remstr = ""
    if (rem >= 0.75) {
        remstr = "-"
        avg = avg + 1
    } else if (rem >= 0.49) {
        remstr = "½"
    } else if (rem >= 0.25) {
        remstr = "+"
    }
    return grades[avg] + remstr
}

const grades = ["I", "ERROR", "A", "B", "C", "M", "E", "L"]

export function SchoolEntry(school) {
    let session = school.session
    school = school.school
    
    let [students, setStudents] = useState(null)
    let [settings, setSettings] = useState({sort: "Keskiarvo", filters: [], enableFilters: false, session: session})
    if (students && !students.success) {
        return (
            <div>
                <p>Palvelimessa tapahtui virhe. Yritä myöhemmin uudelleen.</p>
            </div>
        )
    }
    if (school == null) return
    if (students == null || students.name !== school || settings.session !== session) {
        fetch("https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/statistics/new/"+session+"/school/"+school)
            .then(response => {
                if (!response.ok) throw new Error("Network error")
                return response.json()
                
            })
            .then(data => {
                if (!data.success || !data.hasOwnProperty("name")) {
                    setStudents({
                        success: false,
                        details: "Palvelimessa tapahtui virhe. Yritä myöhemmin uudelleen tai ota yhteys ylläpitoon."
                    })
                }
                setStudents(data)
                gatherSession = session
                setSettings({sort: "Keskiarvo", filters: [], enableFilters: false, session: session})
            }) 
            .catch((error) => {
                setStudents({
                    success: false,
                    details: "Palvelimessa tapahtui virhe. Yritä myöhemmin uudelleen tai ota yhteys ylläpitoon."
                })
            })
            return (
                <div style={{minHeight:"100vh"}}></div>
            )
    } else {

        let handleSortOrderChange = ((event) => {
            setSettings({
                ...settings,
                sort: event.target.textContent
            })
            event.preventDefault()
        })
        let handleAddFilter = ((event) => {
            let filters = settings.filters
            filters.push(event.target.id.substring(4))
            setSettings({
                ...settings,
                filters: filters
            })
            event.preventDefault()
        })
        let handleRemoveFilter = ((event) => {
            let filters = settings.filters
            filters.splice(filters.indexOf(event.target.id.substring(4)), 1)
            setSettings({
                ...settings,
                filters: filters
            })
            event.preventDefault()
        })
        let handleToggleFilterEnabled = ((event) => {
            setSettings({
                ...settings,
                enableFilters: !settings.enableFilters
            })
        })

        let getHeaderString = ((student, item) => {
            if (settings.sort === "Pisteet") {
                return (
                    <>
                        <b>#{student.number+ 1} </b> 
                        &nbsp; 
                        {rnd(student.total)}
                        <span className="HideOnMobile">
                            &nbsp;pistettä
                        </span>
                        &nbsp; 
                        &nbsp;
                        <span style={{color: "#787b7d"}}> 
                            <span className="HideOnMobile">
                                Koearvosanat&nbsp;
                            </span> 
                            <span className="ShowOnMobile">
                                (
                            </span>
                            {grout(student)}
                            <span className="ShowOnMobile">
                                )
                            </span>
                            <span className="HideOnMobile">
                                &nbsp;- {student.sex === 1 ? "mies" : "nainen"}
                            </span>
                        </span>
                    </>
                );
                
            } else {
                return (
                    <>
                        <b>#{student.number + 1} </b> 
                        &nbsp; 
                        <span className="HideOnMobile">
                            Keskiarvo&nbsp;
                        </span>
                        {letteravg(student.average)} 
                        &nbsp; 
                        &nbsp;
                        <span style={{color: "#787b7d"}}> 
                            <span className="HideOnMobile">
                                Koearvosanat&nbsp;
                            </span> 
                            <span className="ShowOnMobile">
                                (
                            </span>
                            {grout(student)}
                            <span className="ShowOnMobile">
                                )
                            </span>
                            <span className="HideOnMobile">
                                &nbsp;- {student.sex === 1 ? "mies" : "nainen"}
                            </span>
                        </span>
                    </>
                )
            }
        })

        let subjects = {}
        students.students.forEach(student => {
            Object.keys(student.grades).forEach(grade => {
                if (!subjects.hasOwnProperty(grade)) subjects[grade] = 0
                subjects[grade]+= 1
            })
        })
        let rankedSubjects = []
        Object.keys(subjects).forEach(subject => {
            if (!settings.filters.includes(subject)) rankedSubjects.push({name: subject, students: subjects[subject]})
        })
        // sort by number of students or in alphabetical order?
        //rankedSubjects.sort((a,b) => a.students - b.students)
        rankedSubjects.sort((a,b) => {
            return students.dictionary[a.name].localeCompare(students.dictionary[b.name])
        })

        let shownStudents = []
        let sortableStudents = students.students

        // sort
        if (settings.sort === "Pisteet") {
            sortableStudents.sort((a,b) => a.total - b.total)
            sortableStudents.reverse()
        } else {
            sortableStudents.sort((a,b) => a.average - b.average)
            sortableStudents.reverse()
        }

        sortableStudents.forEach((student, index) => {
            shownStudents.push({
                ...student,
                number: index
            })
        })
        // filter
        if (settings.filters.length > 0 && settings.enableFilters) {
            shownStudents = shownStudents.filter(student => {
                let allow = true;
                settings.filters.forEach(filter => {
                    if (!student.grades.hasOwnProperty(filter)) allow = false
                })
                return allow
            })
        }
        return (
            <>
                <div className="StatsSchoolInfoContainer">
                    <InfoModule desc="Keskiarvo" rank={students.basics.averageRank} figure={letteravg(students.basics.average)}/>
                    <InfoModule desc="Valmistunutta" rank={students.basics.graduatesRank} figure={students.basics.graduates}/>
                    <InfoModule desc="Laudatureita per oppilas" rank={students.basics.lpsRank} figure={rnd(students.basics.lps)}/>
                </div>
                <hr></hr>
                <div className="StatsStudentContainer">
                    <h3 className="MonospaceFont" style={{textAlign: "center"}}><b>{(session.substring(4,5) === "S" ? "Syksyn " : "Kevään ") + session.substring(0,4)} valmistujat</b></h3>
                    <div className="StatsStudentListSettings">
                        <div>
                            <span className="MonospaceFont">Järjestys: <ButtonGroup>
                                <Button variant={(settings.sort === "Keskiarvo" ? "primary" : "outline-primary")} onClick={handleSortOrderChange}>Keskiarvo</Button>
                                <Button variant={(settings.sort === "Pisteet" ? "primary" : "outline-primary")} onClick={handleSortOrderChange}>Pisteet</Button>
                            </ButtonGroup></span>
                        </div>
                        <br></br>
                        <p><span className="MonospaceFont"><FormCheck style={{color: (settings.enableFilters ? "black" : "lightgray")}} type="switch" id="enable-filter-switch" label="Näytä vain ne, jotka kirjoittivat aineet " inline onClick={handleToggleFilterEnabled}/> 
                            {Array.from({ length: settings.filters.length }).map((_, item) => (
                                <>
                                    <Button className="MiniPill Removable" id={"rbn-" + settings.filters[item]} disabled={!settings.enableFilters} onClick={handleRemoveFilter}>{students.dictionary[settings.filters[item]]}</Button>
                                    &nbsp;ja&nbsp;
                                </>
                            ))}
                            <Dropdown inline className="InlineDiv">
                                <Dropdown.Toggle className="MiniPill" disabled={!settings.enableFilters} inline>+</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    
                                    {Array.from({ length: rankedSubjects.length }).map((_, item) => (
                                        <Dropdown.Item onClick={handleAddFilter} id={"dds-"+rankedSubjects[item].name}> {students.dictionary[rankedSubjects[item].name]} ({rankedSubjects[item].students})</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </span></p>
                    </div>
                    <Accordion>
                        {Array.from({ length: shownStudents.length }).map((_, item) => (
                            <Accordion.Item eventKey={item.toString()}>
                                <Accordion.Header className="MonospaceFont">
                                    {getHeaderString(shownStudents[item], item)}    
                                </Accordion.Header>
                                <Accordion.Body className="MonospaceFont">
                                    <p><b> <span className="ShowOnMobile">{shownStudents[item].sex === 1 ? "Mies" : "Nainen"}, YO-arvosanojen k</span><span className="HideOnMobile">K</span>eskiarvo {rnd(shownStudents[item].average)} ({letteravg(rnd(shownStudents[item].average))})</b></p>
                                    {Array.from({ length: Object.keys(shownStudents[item].grades).length }).map((_, gradekey) => (
                                        <p>{students.dictionary[Object.keys(shownStudents[item].grades)[gradekey]]} &nbsp; {grades[shownStudents[item].grades[Object.keys(shownStudents[item].grades)[gradekey]]]}</p>
                                    ))}
                                    <p><i>Pisteitä yhteensä {rnd(total(shownStudents[item]))}</i></p>
                                    {parseInt(students.percentiles.fiftiethPercentile) < Math.round(shownStudents[item].average * 100) / 100 && students.percentiles.hasOwnProperty((Math.round(shownStudents[item].average * 100) / 100).toString()) && (<p><b>Kokelas on kaikista Suomen valmistujista parhaiden {students.percentiles[(Math.round(shownStudents[item].average * 100) / 100).toString()]}% joukossa.</b></p>)}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </>
        )
    }
}


export function InfoModule(props) {
    return (
        <div className="StatsSchoolInfoContainerItem">
            <h4>
                {props.figure}
            </h4>
            <p>
                {props.desc}
                <br></br>
                <i>#{props.rank} Suomessa</i>
            </p>
        </div>
    )
}

export function SessionSelector(props) {
    let selectedSession = props.session
    let setSession = props.setSession
    let selectedSessionIndex = 0
    for (let s = 0; s <= sessions.length; s++) {
        if (sessions[s] === selectedSession) {
            selectedSessionIndex = s
        }
    }

    let humanSession = ((text) => {
        return (text.substring(4,5) === "S" ? "Syksy " : "Kevät ") + text.substring(0,4)
    })

    let previousSession = selectedSessionIndex + 1
    if (previousSession >= sessions.length) previousSession = -1

    let nextSession = selectedSessionIndex - 1
    if (nextSession < 0) nextSession = -1
    console.log("Sessions: next " + nextSession + ", previous " + previousSession)
    return (
        <>
            <div className="SessionContainer">
                {previousSession !== -1 && <h3 className="SessionHyperlink MonospaceFont" style={{marginRight:"auto"}}><a href="#!" onClick={(event) => {
                    setSession(sessions[previousSession])
                    event.preventDefault()
                }}><b> {"< " + humanSession(sessions[previousSession])}</b></a></h3>}
                {nextSession !== -1 && <h3 className="SessionHyperlink MonospaceFont" style={{marginLeft: "auto"}}><a href="#!" onClick={(event) => {
                    setSession(sessions[nextSession])
                    event.preventDefault()
                }}><b>{humanSession(sessions[nextSession]) + " >"}</b></a></h3>}
            </div>
            <hr></hr>
        </>
    )
}


export default function StatisticsPage() {

    const handleOnSelect = (item) => {
        console.log(item)
        setSelectedSchool(item.name)

    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left', margin: "5px"}}>{item.name + " "}
                    <span class="Badge OutlineObject HideOnMobile">
                        {item.size} valmistunutta
                    </span>
                </span>
            </>
        )
    }
    let [session, setSession ] = useState(sessions[0])
    let [schools, setSchools ] = useState(null)
    let [leaderboard, setLeaderboard] = useState([])
    let [selectedSchool, setSelectedSchool ] = useState(null)

    if (schools === "ERROR") {
        return <p>Virhe: Palvelimessa tapahtui virhe. Yritä myöhemmin uudelleen.</p>
    }

    console.log("Request: " + gatherSession + " " + session + " - " + (gatherSession === session))
    if (schools == null || (gatherSession !== session && selectedSchool == null)) {
        fetch("https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/statistics/new/"+session+"/school_list")
            .then(response => {
                if (!response.ok) throw new Error("Network error")
                return response.json()
            })
            .then(data => {
                if (!data.success) {
                    setSchools("ERROR")
                } else {
                    let out = []
                    gatherSession = session
                    setLeaderboard(data.leaderboard)
                    for (var i in Object.keys(data.list)) {
                        out.push({
                            id: i,
                            name: Object.keys(data.list)[i],
                            size: data.list[Object.keys(data.list)[i]]
                        })
                    }
                    setSchools(out)
                }
            }) 
            .catch((error) => {
                setSchools("ERROR")
                console.log(error)
            })
    } else {
        return (
            <>
                <div className="App-header">
                    <h3>
                        <b>Vuoden {session.substring(0,4) + " " + (session.substring(4,5) === "S" ? "syksyn" : "kevään")} ylioppilaat</b>
                    </h3>
                    <br></br>
                    <div className="SearchBox">
                        <ReactSearchAutocomplete
                            items={schools}
                            fuseOptions={{threshold: 0.3}}
                            onSelect={handleOnSelect}
                            showIcon={false}
                            autoFocus
                            placeholder="Syötä koulun nimi"
                            inputDebounce={50}
                            formatResult={formatResult}
                            styling={{
                                fontFamily: "Courier New",
                                borderRadius: "5px",
                                zIndex: "100",
                            }}
                        />
                    </div>
                    
                </div>
                <SessionSelector session={session} setSession={(sessioncandidate) => {
                    setSession(sessioncandidate)
                }}/>
                {selectedSchool == null &&
                    <>
                        <br></br>
                        <div className="StatsStudentContainer">
                            <h4 className="MonospaceFont" style={{textAlign: "center"}}><b>Korkeimpien keskiarvojen lukiot</b></h4>
                            <ListGroup>
                                {Array.from({ length: leaderboard.length}).map((_, item) => (
                                    <>
                                        <ListGroup.Item className="MonospaceFont HideOnMobile DarkenOnhover" style={{background: "#faf3de"}}><b>{"#" + (item + 1) + " " + leaderboard[item].name}</b>
                                        &nbsp; keskiarvo {rnd(leaderboard[item].average)} ({letteravg(leaderboard[item].average)}),
                                        {" " + leaderboard[item].graduates} valmistunutta</ListGroup.Item>

                                        <ListGroup.Item className="MonospaceFont ShowOnMobile DarkenOnHover" style={{background: "#faf3de"}}><p><b>{"#" + (item + 1) + " " + leaderboard[item].name}</b></p>
                                        <p>Valmistuneiden keskiarvo: {rnd(leaderboard[item].average)} ({letteravg(leaderboard[item].average)})</p>
                                        <p>Valmistuneita: {leaderboard[item].graduates}</p></ListGroup.Item>
                                    </>
                                ))}
                            </ListGroup>
                        </div>
                    </>
                }
                <SchoolEntry school={selectedSchool} session={session}/>
            </>
            
        )
    }
}