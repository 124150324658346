import {Alert, CloseButton, Button, Spinner} from "react-bootstrap"

import { useState } from 'react';
import QuestionBrowser from "./Browser.js"
import { CookiesProvider } from "react-cookie"
import './App.scss';

function up(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function App() {

  const [ subject, setSubject ] = useState(null)
  const [ exam, setExam ] = useState(null)

  const [ data, setData ] = useState(null)
  const [ examInfo, setExamInfo ] = useState(undefined)
  const [ hidePopup, setPopupHidden ] = useState(false)
  
  

  function search(target) {
    setExam(target)
    setData({identifier: subject + "_" + target})
    return;
  }

  function loadExams() {
        
      if (examInfo === undefined) {
          console.log("- LOADING HOMEPAGE DATA FROM API")
          fetch("https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/frontpage2")
              .then(response => {
                  if (!response.ok) throw new Error("Network error")
                  return response.json()
              })
              .then(data => {
                  let newdata = {}

                  newdata.subjects = data.subjects;
                  Object.entries(newdata.subjects).forEach(([k,v]) => {
                    Object.entries(v).forEach(([sub, exam]) => console.log(sub + " " + exam))
                  })
                  newdata.success = data.success;
                  newdata.exams = data.exams
                  setExamInfo(newdata)
                  console.log("Got exam data")
              }) 
              .catch((error) => {

                  setExamInfo({
                      success: false,
                      details: "Palvelimessa tapahtui virhe. Yritä myöhemmin uudelleen tai ota yhteys ylläpitoon."
                  })
              })
      }
  }

  // Load the exams page 
  if (examInfo === undefined) {
    loadExams()
    return <div className="LoadingScreen">
      <Spinner animation="border" variant="secondary" role="status"></Spinner>
      <h5 style={{marginTop:"5vh", color:"rgba(107, 116, 124)"}}>Ladataan kokeita...</h5>
    </div>
  }
  if (!examInfo.success) {
    return <div className="LoadingScreen">
      <h3 style={{color:"rgba(107, 116, 124)"}}>Palvelimessa tapahtui virhe.</h3>
      <h5 style={{marginTop:"5vh", color:"rgba(107, 116, 124)"}}>Yritä myöhemmin uudelleen. Pyrimme korjaamaan ongelman pikimmiten.</h5>
      </div>
  }

  const queryParams = new URLSearchParams(window.location.search)
  if (queryParams.get("answer") !== null && data == null) {
    let queryExam = null
    let questionNumber = null

    for (var candidate of examInfo.exams) {
      for (var candidateQuestion of Object.keys(candidate.questions)) {
        for (var candidateAnswer of candidate.questions[candidateQuestion].answers) {
          if (candidateAnswer.identifier === queryParams.get("answer")) {
            queryExam = candidate.identifier
            questionNumber = candidateQuestion
            break
          }
        }
        if (queryExam) break
      }
      if (queryExam) break
    }

    setData({
      identifier: queryExam,
      openQuestion: questionNumber,
      openAnswer: queryParams.get("answer")
    })

  }

  return (

    <div className="App">
      <br></br>
      <Alert hidden={hidePopup} className="PopupWindow" variant="success">
      <CloseButton onClick={(() => {
        setPopupHidden(true)
      })}></CloseButton>
        <Alert.Heading>
          <b>Vuoden 2024 ylioppilaiden tilastot on julkaistu!</b>
        </Alert.Heading>
        <Button href="/tilastot" style={{marginBottom: "2%", marginTop:"2%", minWidth: "40%"}}><b>Siirry tilastosivulle</b></Button>
      </Alert>

      <header className="App-header">
        <h2 style={{marginTop: "2em"}}>Aiempien ylioppilaskokeiden mallivastauksia. Ilmaiseksi.</h2>
      <div className="SearchFormBackground">
        <div className="horizontalRow">
          {Array.from({length: Object.keys(examInfo.subjects).length}).map((_, idx) => {
            return <Button style={{color: "#"}} onClick={(e) => {
              setSubject(Object.keys(examInfo.subjects)[idx])
              setExam(null)
            }
          } active={subject === Object.keys(examInfo.subjects)[idx]}>{up(Object.keys(examInfo.subjects)[idx])}</Button>
          })}
        </div>
        {subject != null && 
          
          <div className="horizontalRow">
            <hr></hr>
            {Array.from({length: examInfo.subjects[subject].length}).map((_, idx) => {
              return <Button onClick={(e) => search(examInfo.subjects[subject][idx][0])} active={exam === examInfo.subjects[subject][idx][0]}>{up(examInfo.subjects[subject][idx][0].replaceAll("_", " ")) + " (" + examInfo.subjects[subject][idx][1] + (examInfo.subjects[subject][idx][1]  !== 1 ? " vastausta)" : " vastaus)")}</Button>
            })}
          </div>
        }
              
      </div>
      <p className = "SmallText" style={{maxWidth: "90%"}}>Kirjoititko Laudaturin? Helpota muiden lukutaakkaa ja jaa omat vastauksesi anonyymisti <a target="_blank" href="/submit">tästä</a>.</p>
      </header>
      <div>
        <br></br>
        <CookiesProvider>
          <QuestionBrowser props={data} exams={examInfo.exams}/>
        </CookiesProvider>
      </div>
    </div>
  );
}

export default App;
